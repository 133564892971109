$(function() {
  $('[data-mask]').inputmask()
  $("#inputOTP").hide()
  $("#getLogin").hide()
    const token = localStorage.getItem("remember")
    if(token){
      $(".card-body").html(`<button id="sCheck" class="btn btn-block btn-success">Touch to Unlock</button>`)
      $("#sCheck").click(async function(event) {
        event.preventDefault()
        const data = await API('api/passport.php','POST',{'device':navigator.userAgent,'new_session':getCookieValue('PHPSESSID'),'old_session':localStorage.getItem("PHPSESSID"),'token':token})
        if(data.status){
          localStorage.setItem("remember", data.data)
          localStorage.setItem("PHPSESSID", getCookieValue('PHPSESSID'))
          window.location.replace("index.php")
          return
        }else{
          localStorage.clear()
          window.location.replace("login.php")
          return
        }
      })
    }else{
      localStorage.clear()
    }
})

$("input[name='mobile']").keyup(function() {
  let mobile = $(this).val().replace(/\D/g,'')
  if(mobile.length===10){
    $("#verifyUser").prop('disabled', false);
  }else{
    $("#verifyUser").prop('disabled', true);
  }
})

$("input[name='otp']").keyup(function() {
  let mobile = $(this).val().replace(/\D/g,'')
  if(mobile.length===6){
    $("#getLogin").prop('disabled', false);
  }else{
    $("#getLogin").prop('disabled', true);
  }
})


$("#verifyUser").click(async function(event) {
  event.preventDefault()
  $(this).prop('disabled', true);
  const data = await API('api/login.php','POST',{'action':'otp','mobile':$("input[name='mobile']").val()})
  if(data.status){
    $(this).hide()
    $("#message").text('กรุณายืนยันตัวตน ด้วยรหัสที่ได้รับผ่านทาง SMS')
    $("input[name='mobile']").addClass('is-valid text-success').prop('disabled', true);
    $("#inputOTP").show()

   // $("input[name='otp']").val(data.OTP??'')

    $("#getLogin").show()
    await delay(10000);
    $("#message").removeClass('text-primary').addClass('text-danger').html('หากยังไม่ได้รับ SMS กรุณารออีกสักครู่...')
    await delay(60000);
    $("#message").html('เครือข่ายมือถือขัดข้อง <br><span class="btn btn-danger mt-3">ลองใหม่ อีกครั้ง</span> ').click(()=>window.location.replace("index.php"))
    $("input").hide()
    $("#getLogin").hide()
  }else{
    $("#message").removeClass('text-primary').addClass('text-danger').text(data.message)
    $(this).prop('disabled', false);
  }
})

$("#getLogin").click(async function(event) {
  event.preventDefault()
  $(this).prop('disabled', true).html('<div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>')
  const postData = {'device':navigator.userAgent,'mobile':$("input[name='mobile']").val(),'otp':$("input[name='otp']").val(),'action':'login'}
  const data = await API('api/login.php','POST',postData)
  if(data.status){
    localStorage.clear()
    localStorage.setItem("remember", data.data)
    localStorage.setItem("PHPSESSID", getCookieValue('PHPSESSID'))
    window.location.replace("index.php")
  }else{
    $("#message").removeClass('text-primary').addClass('text-danger').text(data.message)
    $(this).prop('disabled', false).html('ลองใหม่อีกครั้ง '+'('+data.attempt+')')
  }
})

